import React from "react"
import {Container} from 'vactory-ui'
import PostApplyForm from "./post.applyForm"

const PostApplyFormContainer = ({pageContext: {node}}) => {
    return <Container>
        <PostApplyForm post={node} />
    </Container>
};

export default PostApplyFormContainer
