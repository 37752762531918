import React from "react"
import {Form, useWebform} from 'vactory-gatsby-webform';
import {
    Heading,
} from 'vactory-ui'


const PostApplyForm = ({post, ...rest}) => {
    const formId = 'job_application';
    const webform = useWebform(formId);

    return (
        <>
            <Heading my={30} level={3}>{post.title}</Heading>

            <Form
                webformId={formId}
                schema={webform.elements}
                buttons={{
                    reset: {
                        hidden: true,
                    }
                }}
            />
        </>
    )
};

export default PostApplyForm
